@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
      font-family: "cm";
      src: local("fantasyOG"),url(../src/fonts/cmunrm.woff) format('woff2');
  }
    
  @font-face {
    font-family: "Tomasa";
    src: local("Tomasa"), url( ../src/fonts/Tomasa.woff2) format('woff2'), url( ../src/fonts/Tomasa.woff) format('woff'), url( ../src/fonts/Tomasa.ttf) format('truetype');
  }

  html {
    @apply antialiased;
  }
  mux-player {
    --seek-backward-button:none;
    --seek-forward-button:none;
  }


  
}

@layer components {
  .wrapper {
    @apply rounded-[35px] border border-black p-6 lg:p-8 overflow-y-scroll scrollbar scrollbar-thumb-gray scrollbar-thin touch-auto;
  }

  .shadows {
    @apply lg:rounded-[35px] lg:overflow-hidden shadow-2xl;
  }

  .bg-roundy {
    @apply rounded-[35px]
  }
}